import React, { Component, useEffect, useState,useRef } from 'react';
import './BudgetTable.css'
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { Row, Col,Spinner,Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import moment from "moment-timezone";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import ActionDot from "../../../assets/images/icons/more-action-icon.svg";
import { Popconfirm } from "antd";
import { toast } from "react-toastify";

interface CampaignSchedules{
    scheduleName:string;
    campaignIds:string;
    updatedAt:string;
    _id: string;
}
interface CampaignSchedulesProps{
    condition: string;
  }
  interface Assign{
    name:string;
    pageCount: number;
  }
  interface ScheduleData {
    _id:any;
    scheduleName: string;
    profileId: string | number;
    countryCode: string;
    timezone: string;
  }
  
  const CampaignSchedules: React.FC<CampaignSchedulesProps> = (props) => {
  const [CampaignSchedules,setCampaignSchedules]=useState<CampaignSchedules[]>([])
  const [perPage, setPerPage] = useState(50);
  const [totalRow, setTotalRow] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [apiLoading, setApiLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);  
  const [showOptions, setShowOptions] = useState<string | null>(null);
  const [assign, setAssign] = useState<Assign[] | null>(null); 
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const currentItems = assign ? assign.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage) : [];
  const pageCount = Math.ceil(assign ? assign.length / itemsPerPage : 0);
  const [searchCampaign, setsearchCampaign] = useState("");
  const [AddSchedulerModal, setAddSchedulerModal] = useState(false);
  const [scheduleName, setscheduleName] = useState("");
  const [editRules, setEditRules] = useState<string[]>([]);
  const [ids,setId]=useState("");
  const [ExportSchedules,setExportSchedules]=useState(false);
  const [editScheduleData, seteditScheduleData] = useState<ScheduleData | null>(null);

  const toggleOptions = (id: string) => {
    console.log("haashim")
    setShowOptions(showOptions === id ? null : id); 
  };

  const handleChange = (event, value: number) => {
    setActivePage(Number(value))
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    setActivePage(1)
    setPerPage(Number(e.target.value)) 
  };

  const handleInputChange = (e) => {
    setsearchCampaign(e.target.value); 
  };
  
 
  const handleSearchClick = () => {
    if (CampaignSchedules.length > 0) {
      handleAssign(CampaignSchedules[0]._id); 
    } else {
      console.error("No BudgetRules available to assign");
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  
  // Reset the search field and fetch all data
  const handleReset = async () => {
  
    setsearchCampaign(""); 
    
    if (CampaignSchedules.length > 0) {
      try {
        await handleAssign(CampaignSchedules[0]._id, ""); 
  
  
        console.log("Fields and API have been reset.");
      } catch (error) {
        console.error("Error while resetting: ", error);
      }
    } else {
      console.error("No BudgetRules available to assign");
    }
  
  };

  const fetchgetSchedules = async (activePage: number, perPage: number, condition: string) => {
    let userToken = localStorage.getItem("budgetOptimizer");
    let AuthToken = "Bearer " + userToken;
    let profileId = localStorage.getItem("BudgetProfileId")
    console.log(profileId,"BudgetProfileId")
    setSpinner(true);
      try {
          const response = await fetch(
              `https://budget-api.getgrowth.agency/api/amazon/adSchedules/${profileId}/getSchedules`,
              {
                  method: 'POST', 
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: AuthToken,
                  },
                  body: JSON.stringify({
                      pageNo: activePage,
                      perPage:perPage ,
                      // type: selectedProfile,
                      // status:selectedstatus,
                      // currencyCode: 'USD',
                      // search: searchKeyFilter,
                      // timezone: 'America/Los_Angeles',
                  }),
              }
          );

          if (!response.ok) {
              throw new Error('Failed to fetch campaigns data.');
          }

          const data = await response.json();
          if (data?.data) {
            setCampaignSchedules(data.data);
              setTotalRow(data.totalRecords)
              setActivePage(data.currentPageNo)
              setLastPage(data.totalPages)
              
          }
          setSpinner(false);
          console.log(data.data, "Fetched Campaigns Data");
      } catch (err) {
        setSpinner(false);
        console.error(err);
       } finally {
        setApiLoading(false); 
      }

  };

  useEffect(() => {
    fetchgetSchedules(activePage,perPage,props.condition);
      }, [activePage,perPage,props.condition]); 
      

      const handlScheduleEdit = async () => {
        
        let userToken = localStorage.getItem("budgetOptimizer");
        let AuthToken = "Bearer " + userToken;
        let profileId = localStorage.getItem("BudgetProfileId");
      
        setSpinner(true); 
        setAddSchedulerModal(true);
        try {
          const response = await fetch(
            `https://budget-api.getgrowth.agency/api/amazon/adSchedules/updateSchedule/${editScheduleData?._id}`,
            {
              method: "POST", 
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthToken,
              },
              body: JSON.stringify({
                scheduleName: scheduleName, 
                profileId: editScheduleData?.profileId, 
                countryCode: editScheduleData?.countryCode,
                timezone: editScheduleData?.timezone, 
              }),
            }
          );
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          setAddSchedulerModal(false);
          toast.success(data.message || "Updated successfully!");
          fetchgetSchedules(activePage,perPage,props.condition);
        } catch (error) {
          console.error("Error fetching data:", error); 
        } finally {
          setSpinner(false); 
        }
      };
      const handleEditData= async(campaignSchedulesData:any)=>{
        setAddSchedulerModal(true);
        setscheduleName(campaignSchedulesData?.scheduleName)
        setId(campaignSchedulesData?._id);
        seteditScheduleData(campaignSchedulesData);
        console.log(campaignSchedulesData,"editoneDaa"); 
      }
      
      const handleDelete = (rule: CampaignSchedules) => {
        console.log("Delete clicked for:", rule);
      };

      const handleAssign = async (id: string,search: string = "") => {
        let userToken = localStorage.getItem("budgetOptimizer");
        let AuthToken = "Bearer " + userToken;
        let profileId = localStorage.getItem("BudgetProfileId")
          setSpinner(true);
          try {
            const response = await fetch (
              `https://budget-api.getgrowth.agency/api/amazon/adSchedules/getAssignCampaigns/${id}`,
              {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: AuthToken,
                },
                body: JSON.stringify({
                search: searchCampaign,
                pageNo: activePage,
                perPage: perPage,
              })
            }
          );
            setSpinner(false);
            const data = await response.json();
            setAssign(data.data); 
            console.log(data.data,"hahhahh")
      
          } catch (error) {
            setSpinner(false);
            console.error("Error fetching data:", error);
          }
        };

        const createSchedule = async () => {
         
          let userToken = localStorage.getItem("budgetOptimizer");
          let AuthToken = "Bearer " + userToken;
          let profileId = localStorage.getItem("BudgetProfileId");   
          
          try {
            const response = await fetch(
              "https://budget-api.getgrowth.agency/api/amazon/adSchedules/createSchedule",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: AuthToken,
                },
                body: JSON.stringify({
                  scheduleName: scheduleName,
                  profileId: profileId,
                  countryCode:"US",
                  timezone:"America/Los_Angeles"
                 
                }),
              }
            );
        
          const data = await response.json();
          setAddSchedulerModal(data.data);
          setAddSchedulerModal(false);
          toast.success(data.message || "Created Successfully!");
          fetchgetSchedules(activePage,perPage,props.condition);
        } catch (toast) {
          console.error("Error fetching data:", toast);
        }
      };
        
      const handleExport = async () => {
          let userToken = localStorage.getItem("budgetOptimizer");
          let AuthToken = "Bearer " + userToken;
          let profileId = localStorage.getItem("BudgetProfileId");   
          
          try {
            const response = await fetch(
               `https://budget-api.getgrowth.agency/api/amazon/adSchedules/${profileId}/exportSheet`,
              {
                method: "Get",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: AuthToken,
                },
              
              }
            );
         
          const data = await response.json();
          toast.success(data.message || "Export successful!");
          console.log("API Response:", data.data);
        } catch (toast) {
          console.error("Error fetching data:", toast);
        }
      };

   const deleterule = async (id: any) => {
          let userToken = localStorage.getItem("budgetOptimizer");
          let AuthToken = "Bearer " + userToken;
         
            try {
              const response = await fetch (
                `https://budget-api.getgrowth.agency/api/amazon/adSchedules/deleteSchedule/${id}`,
                {
                  method: 'DELETE', 
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: AuthToken,
                  },
              }
            );
              setSpinner(false);
              const data = await response.json();
              toast.success(data.message || "Deleted Successfully!");
              fetchgetSchedules(activePage,perPage,props.condition);
            } catch (error) {
              setSpinner(false);
              console.error("Error fetching data:", error);
            }
  };
         

  return (
    
    <div>
      <div className="col">
        <div>
          <Row className="mt-2 mb-2">
            <Col style={{justifyContent:"flex-end",display:"flex" }}> 
            <div className="campaigns">
            <button
              className="handleAddToTemplateClick"
              onClick={() => {
                // setAddFlag(true);
                // setEditId("");
                setAddSchedulerModal(true);
              }}
            >
              Create Schedule
            </button>
            <button
              className="Top-button"
              onClick={() => handleExport()}
            >
              Export Schedules
            </button>
            </div>
            </Col>
                  </Row>
            </div>
            
    <div className='Budget-table' style={{ height: 500, width: "100%" }}>
            <table className= "full-table" cellPadding="10" style={{ width: '100%', borderCollapse: 'collapse'}}>
                <thead className='table-heaad'>
                    <tr>
                        <th>Schedule Name</th>
                        <th># of Campaigns</th>
                        <th>Updated At</th>
                        <th></th>                     
                    </tr>
                </thead>
                <tbody className='tbody-td'>
                  {spinner ? (
                      <td colSpan={8} style={{ textAlign: 'center', height: '450px', verticalAlign: 'middle' }}>
                        <div className="loading-container">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      </td>
                    ) :
                    Array.isArray(CampaignSchedules) && CampaignSchedules.map((CampaignSchedules,index) => (
                        <tr className='tbody-tr:hover' key={index}> 
                             
                            <td>{CampaignSchedules.scheduleName}</td>
                          
                            <td style={{ color: Array.isArray(CampaignSchedules.campaignIds) && CampaignSchedules.campaignIds.length > 0 ? 'blue' : 'black' }}
                              onClick={() => handleAssign(CampaignSchedules._id)}
                              >
                            {Array.isArray(CampaignSchedules.campaignIds) ? CampaignSchedules.campaignIds.length : "-"}
                          </td>
                          
                          <td>
                            {CampaignSchedules.updatedAt ? (
                              (() => {
                                const timeZoneCountry = "America/Los_Angeles"; 
                                const combinedDateTime = CampaignSchedules.updatedAt; 
                                const formattedDateTime = moment
                                  .utc(combinedDateTime) 
                                  .tz(timeZoneCountry) 
                                  .format("MM/DD/YYYY hh:mm:ss A z");
                                return formattedDateTime;
                              })()
                            ) : (
                              "-"
                            )}
                          </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                           {/* <span
                              onClick={() => toggleOptions(CampaignSchedules._id)} 
                              className="threedot"
                              style={{ cursor: 'pointer' }}
                            >
                              <BsThreeDotsVertical className="threedot" />
                            </span>
      
                            
                            {showOptions === String(CampaignSchedules._id) && ( 
                              <div className="options">
                                <button onClick={() => handleEdit(CampaignSchedules)} className="edit-button">Edit</button>
                                <button onClick={() => handleDelete(CampaignSchedules)} className="red-button">Delete</button>
                              </div>
                            )} */}

                  <div className="col-action-container text-right">
                    <div
                      id={"dropdownMenuButton" + ""}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={ActionDot} alt="actions" />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby={"dropdownMenuButton" + ""}
                    >
                      {/* <li>
                        <Link
                          to={
                            ""
                          }
                        >
                        
                          <p className="dropdown-item">Assign</p>
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to={""}
                        >
                          <p className="dropdown-item" onClick={() => handleEditData(CampaignSchedules)}>Edit </p>
                        </Link>
                      </li>
                      <li>
                      <Popconfirm
                          title="Delete"
                          description={`Are you sure you want to delete the Schedules "${CampaignSchedules.scheduleName}"?`}
                          onConfirm={() => deleterule(CampaignSchedules._id)} 
                          okText="Yes"
                          cancelText="No"
                        >
                          <p className="dropdown-item text-red" style={{ cursor: "pointer" }}>
                            Delete
                          </p>
                        </Popconfirm>
                        </li>
                    </ul>
                  </div>
                        </td>
                        </tr>
                    ) , (
                      <tr>
                        <td  style={{ textAlign: "center" }}>
                        No rows
                        </td>
                      </tr>
                    ))}
                  </tbody>
            </table> 
        </div>
        <div className="custom-table-footer">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={perPage}
                      onChange={(event) => applyDataLength(event)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {totalRow} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={lastPage}
                      page={activePage}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div> 
          <div>

            <>
              {AddSchedulerModal && (
                <Modal
                  show={AddSchedulerModal}
                  onHide={() => setAddSchedulerModal(false)}
                  size="lg"
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <div className="schedulename-modal-contentpopup">
                    <Modal.Header closeButton
                    onHide={()=>{setId("");setscheduleName("")}}>
                      <Modal.Title id="example-modal-sizes-title-lg" style={{ fontSize: "17px" }}>
                        {ids ? "Edit Schedule" : "Create Schedule"}
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Row>
                        <div>
                          Schedule Name
                          <input
                            type="text"
                            className="text_schedulename"
                            placeholder="Enter Schedule Name"
                            value={scheduleName}
                            onChange={(e) => setscheduleName(e.target.value)}
                          />
                        </div>
                      </Row>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                    <button
                      className="Top-button"
                      style={{ width: "200px" }}
                      onClick={() => setAddSchedulerModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="handleAddToTemplateClick"
                      style={{ width: "200px" }}
                      onClick={() => {
                        if (ids) {
                          handlScheduleEdit();
                        } else {
                          createSchedule();
                        }
                      }}
                    >
                      {ids ? "Update" : "Create" }
                    </button>
                  </Modal.Footer>
                </div>
              </Modal>

              )}
            </>
          </div>
        </div>
        </div>
        
  );
};

export default CampaignSchedules;

